$(function () {
  var $width = $("#slides img").width();
  var $windowH = $(window).width();
  if ($windowH < 768){
    var $heightImg = $width * .35;
  }else {
    var $heightImg = $width * .25;
  }
  $("#slides").slidesjs({
    width: $width,
    height: $heightImg,
    play: {
      active: false,
      // [boolean] Generate the play and stop buttons.
      // You cannot use your own buttons. Sorry.
      effect: "slide",
      // [string] Can be either "slide" or "fade".
      interval: 5000,
      // [number] Time spent on each slide in milliseconds.
      auto: true,
      // [boolean] Start playing the slideshow on load.
      swap: false,
      // [boolean] show/hide stop and play buttons
      pauseOnHover: false,
      // [boolean] pause a playing slideshow on hover
      restartDelay: 2500
      // [number] restart delay on inactive slideshow
    }
  });
});
